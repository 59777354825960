<template>
  <div>
    <md-empty-state
     
        v-if="notification.length===0"
      md-icon="event_available"
      md-label="No Notification"
      md-description="You currently have no notification"
    >
      <!-- <md-button class="md-primary md-raised"></md-button> -->
    </md-empty-state>
    <div v-if="notification.length!==0">
      <md-list class="md-triple-line">
        <md-list-item  v-for="msg in notification" v-bind:key="msg.ref" >
          <md-avatar>
            <img src="/images/favicon.png" alt="msg.title" />
          </md-avatar>

          <div class="md-list-item-text">
            <span>{{msg.subject}}</span>
            <span>{{msg.title}}</span>
            <p>
            {{msg.desc}}
            </p>
          </div>

          <md-button class="md-icon-button md-list-action">
            <!-- <md-icon class="md-primary">star</md-icon> -->
          </md-button>
        </md-list-item>
      </md-list>
    </div>
  </div>
</template>





<script>



// export interface INotificationInterface {
//     subject?: string,
//     title: string,
//     desc: string,
//     email: string
//     ref?: string,
//     uid: string,
//     seen?: boolean,
//     timestamp?: number,
// }


export default {
  name: "notification",
  data() {
    return {
      notification: this.$store.state.notification,
    };
  },
};
</script>